import {swalUnknown, toggleLoading} from "../functions";
import Tooltip from 'bootstrap/js/src/tooltip';
import feather from "feather-icons";

function _cws_onSaveButtonCWS(event) {
    let cwsHelper = $('#cws-helper');
    let form = $("#change-webshop-stock-form");

    let swalWithBootstrapButtons = Swal.mixin({
        customClass:
            {
                confirmButton: "btn btn-primary me-2",
                cancelButton: "btn btn-secondary"
            },
        buttonsStyling: false
    });

    if (checkPrices()) {
        event.preventDefault();
        swalWithBootstrapButtons.fire({
            title: cwsHelper.data('confirm'),
            icon: "warning",
            position: 'top',
            showCancelButton: true,
            confirmButtonText: cwsHelper.data('save'),
            cancelButtonText: cwsHelper.data('back')
        }).then(function (result) {
            if (result.value === true) {
                if (form[0].checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }

                form.addClass('was-validated');
                form.trigger('submit');
            }
        });
    } else {
        if (form[0].checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        form.addClass('was-validated');
    }
}

function _cws_onChangeWebshopStockForm(event) {
    toggleLoading('#saveButtonCWS', '#loadingButtonCWS');
    event.preventDefault();

    let form = $(this);
    let fd = new FormData(form[0]);
    let url = $('#cws-helper').data('url');
    let guid = $('#change-webshop-stock-guid').val();
    let inputs = $(this).find("input, select, button, textarea");

    inputs.prop("disabled", true);
    fd.append('guid', guid);

    $('.webshop-status').html(
        '<div class="progress progress-bar-primary">' +
        '    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"' +
        '         aria-valuenow="100" aria-valuemin="100" aria-valuemax="100" style="width: 100%;"></div></div>'
    );

    let notifUrl = new URL($('.notification-options').data('url'));
    notifUrl.searchParams.append('topic', guid);

    let stream = new EventSource(notifUrl.toString());
    stream.onmessage = event => {
        let data = JSON.parse(event.data);
        let shopDiv = $('#shop-card-' + data.shop + ' .webshop-status');

        if (data.success) {
            shopDiv.html('<span class="text-success"><i data-feather="check"></i> ' + data.message + '</span>');
        } else {
            shopDiv.html('<span class="text-danger"><i data-feather="x"></i> ' + data.message + '</span>');
        }

        feather.replace({
            width: 14,
            height: 14
        });
    };

    stream.onopen = () => {
        $.ajax({
            url: url,
            type: 'POST',
            data: fd,
            cache: false,
            contentType: false,
            processData: false,
            async: true
        }).always(function () {
            inputs.prop("disabled", false);
            toggleLoading('#saveButtonCWS', '#loadingButtonCWS');
            stream.close();
        }).fail(function (e) {
            swalUnknown(e);
        });
    };
}

function _cws_onDeleteShopEntry(event) {
    let cwsHelper = $('#cws-helper');

    let swalWithBootstrapButtons = Swal.mixin({
        customClass:
            {
                confirmButton: "btn btn-danger me-2",
                cancelButton: "btn btn-secondary"
            },
        buttonsStyling: false
    });

    swalWithBootstrapButtons.fire({
        title: cwsHelper.data('delete-confirm'),
        icon: "warning",
        position: 'top',
        showCancelButton: true,
        confirmButtonText: cwsHelper.data('delete-button'),
        cancelButtonText: cwsHelper.data('back')
    }).then(function (result) {
        if (result.value === true) {
            $.ajax({
                url: cwsHelper.data('delete-url'),
                type: "post",
                data: {"ID": $(event.target).parents('.delete-shop-entry').data('target')},
                async: true
            }).done(function (response) {
                if (response.success) {
                    $(event.target).parents('.row').find('.input-group-text, .form-control:not([readonly])').addClass('bg-light-danger');
                    $(event.target).parents('.row').find('input:not([readonly])').val('0');
                    $(event.target).parents('.row').find('.create-shop-product').toggleClass('d-none');
                    $(event.target).toggleClass('d-none');
                }
            }).fail(function (e) {
                swalUnknown(e);
            });
        }
    });
}

function _cws_onCreateShopProduct(event) {
    let cwsHelper = $('#cws-helper');

    let swalWithBootstrapButtons = Swal.mixin({
        customClass:
            {
                confirmButton: "btn btn-success me-2",
                cancelButton: "btn btn-secondary"
            },
        buttonsStyling: false
    });

    swalWithBootstrapButtons.fire({
        title: cwsHelper.data('create-confirm'),
        icon: "question",
        position: 'top',
        showCancelButton: true,
        confirmButtonText: cwsHelper.data('create-button'),
        cancelButtonText: cwsHelper.data('back')
    }).then(function (result) {
        if (result.value === true) {
            $.ajax({
                url: cwsHelper.data('create-url'),
                type: "post",
                data: {
                    "ID": $(event.target).parents('.create-shop-product').data('target'),
                    "count": $(event.target).parents('.row').children('.product-count').val() ?? 0,
                    "normal": $(event.target).parents('.row').children('.product-normal').val() ?? 0,
                    "sale": $(event.target).parents('.row').children('.product-sale').val() ?? 0
                },
                async: true
            }).done(function (response) {
                if (response.success) {
                    $(event.target).parents('.row').find('.input-group-text, .form-control:not([readonly])').removeClass('bg-light-danger');
                    $(event.target).parents('.row').find('.delete-shop-entry').toggleClass('d-none');
                    $(event.target).toggleClass('d-none');
                }
            }).fail(function (e) {
                swalUnknown(e);
            });
        }
    });
}

export function initChangeWebshopStockModal() {
    let cwsHelper = $('#cws-helper');

    window.changeProduct = function (id) {
        $.ajax({
            url: cwsHelper.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            $("#change-webshop-stock-modal-form").html(response.data);

            let tooltipTriggerList = [].slice.call(document.querySelectorAll('.has-tooltip'));

            tooltipTriggerList.map(function (tooltipTriggerEl) {
                return new Tooltip(tooltipTriggerEl);
            });

            feather.replace({
                width: 14,
                height: 14
            });
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.checkPrices = function () {
        let base = parseInt($('#change-webshop-stock-price').val().replace(/\s/g, ''), 10);
        let unusual = false;

        let normal = $("[id^=change-webshop-stock-price1-]");
        let sale = $("[id^=change-webshop-stock-price2-]");

        normal.each(function (i, s) {
            let val = parseInt($(s).val().replace(/\s/g, ''), 10);
            if (val > 0 && val < base) {
                unusual = true;
                $(s).addClass('bg-warning is-invalid');
            }
        });

        sale.each(function (i, s) {
            let val = parseInt($(s).val().replace(/\s/g, ''), 10);
            if (val > 0 && val < base) {
                unusual = true;
                $(s).addClass('bg-warning is-invalid');
            }
        });

        return unusual;
    };

    $("#saveButtonCWS").on("click", _cws_onSaveButtonCWS);
    $(document).on("click", '.delete-shop-entry', _cws_onDeleteShopEntry);
    $(document).on("click", '.create-shop-product', _cws_onCreateShopProduct);
    $("#change-webshop-stock-form").on("submit", _cws_onChangeWebshopStockForm);
}

export function unloadChangeWebshopStockModal() {
    delete window.changeProduct;
    delete window.checkPrices;

    $("#saveButtonCWS").off("click", _cws_onSaveButtonCWS);
    $(document).off("click", '.delete-shop-entry', _cws_onDeleteShopEntry);
    $(document).off("click", '.create-shop-product', _cws_onCreateShopProduct);
    $("#change-webshop-stock-form").off("submit", _cws_onChangeWebshopStockForm);
}
