import {checkResponsive, deleteDialog, swalUnknown} from "../functions";
import blockUI from 'jquery-blockui';
import Sortable from 'sortablejs';
import ApexCharts from 'apexcharts';
import feather from "feather-icons";

import magnificPopup from 'magnific-popup';
import {initChangeWebshopStockModal, unloadChangeWebshopStockModal} from "../modals/change_webshop_stock";

import '../../app/css/plugins/charts/chart-apex.css';
import '../../app/vendors/css/charts/apexcharts.css';
import 'magnific-popup/dist/magnific-popup.css';

function onReloadCard_87787b9d() {
    reloadCard($(this));
}

export function initDashboardPage() {
    let helper = $('#helper');
    let w = "#ebe9f1", months, values;

    require('jquery-sticky');

    if (helper.data('introduced') === 0) {
        require('../tour');
    }

    initChangeWebshopStockModal();

    window.blockUI = blockUI;
    window.Sortable = Sortable;
    window.ApexCharts = ApexCharts;
    window.magnificPopup = magnificPopup;

    window.reloadCard = function (e) {
        let blockEle = e.closest('.card');
        let reloadActionOverlay;

        if ($('html').hasClass('dark-layout')) {
            reloadActionOverlay = '#10163a';
        } else {
            reloadActionOverlay = '#fff';
        }

        blockEle.block({
            message: feather.icons['refresh-cw'].toSvg({class: 'font-medium-1 spinner text-primary'}),
            timeout: 0,
            overlayCSS: {
                backgroundColor: reloadActionOverlay,
                cursor: 'wait'
            },
            css: {
                border: 0,
                padding: 0,
                backgroundColor: 'none'
            }
        });

        $.ajax({
            url: blockEle.children('.card-content').children('.ajax-content').data('url'),
            type: "post",
            async: true
        }).done(function (response) {
            blockEle.unblock();

            if (response.success) {
                blockEle.children('.card-content').children('.ajax-content').html(response.data);
            }

            feather.replace({
                width: 14,
                height: 14
            });

            $('.image-popup-vertical-fit').magnificPopup({
                type: 'image',
                closeOnContentClick: true,
                mainClass: 'mfp-img-mobile',
                image: {
                    verticalFit: true
                }
            });
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.scrapProduct = function (id, name) {
        deleteDialog(id, decodeURI(name), helper.data('scrap-confirm'), helper.data('scrap'), helper.data('url'), () => {
            $(id + "-row").remove();
        });
    };

    $('a[data-action="reload"]').on('click', onReloadCard_87787b9d);

    $.ajax({
        url: helper.data('radar'),
        type: "post",
        async: true
    }).done(function (response) {
        months = response.sales_radar.months;
        values = response.sales_radar.values;

        $('#c10 .ajax-content').html(response.unuploaded_products);
        $('#c9 .ajax-content').html(response.nonprofitable_products);
        $('#c6 .ajax-content').html(response.different_price);
        $('#c5 .ajax-content').html(response.different_stock);
        $('#c4 .ajax-content').html(response.orphan_products);
        $('#c3 .ajax-content').html(response.unfinished_orders);
        $('#c2 .ajax-content').html(response.expired_products);

        let salesChart = new ApexCharts(document.querySelector("#sales-chart"), {
            chart: {
                height: 300,
                type: "radar",
                dropShadow: {
                    enabled: true,
                    blur: 8,
                    left: 1,
                    top: 1,
                    opacity: 0.2
                },
                toolbar: {
                    show: false
                },
                offsetY: 5
            },
            series: [{
                data: Object.values(values)
            }],
            stroke: {
                width: 0
            },
            colors: [window.colors.solid.primary, window.colors.solid.info],
            plotOptions: {
                radar: {
                    polygons: {
                        strokeColors: [w, "transparent", "transparent", "transparent", "transparent", "transparent"],
                        connectorColors: "transparent"
                    }
                }
            },
            fill: {
                type: "gradient",
                gradient: {
                    shade: "dark",
                    gradientToColors: [window.colors.solid.primary, window.colors.solid.info],
                    shadeIntensity: 1,
                    type: "horizontal",
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100, 100, 100]
                }
            },
            markers: {
                size: 0
            },
            legend: {
                show: false
            },
            labels: Object.values(months),
            dataLabels: {
                background: {
                    foreColor: [w, w, w, w, w, w]
                }
            },
            yaxis: {
                show: false
            },
            grid: {
                show: false,
                padding: {
                    bottom: -27
                }
            }
        });

        salesChart.render().then();
        $('#sales-chart').data('chart', salesChart);

        feather.replace({
            width: 14,
            height: 14
        });

        $('.image-popup-vertical-fit').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            mainClass: 'mfp-img-mobile',
            image: {
                verticalFit: true
            }
        });
    }).fail(function (e) {
        swalUnknown(e);
    });

    if (!checkResponsive()) {
        $('#card-drag-area').data('sort', Sortable.create(document.getElementById('card-drag-area'), {
            animation: 150,
            draggable: '.draggable',
            dataIdAttr: 'id',
            dragoverBubble: true,
            delay: 1000,
            delayOnTouchOnly: true,
            group: "CrossBolt_dashboard",
            store: {
                get: function (sortable) {
                    let order = localStorage.getItem(sortable.options.group.name);

                    if (order) {
                        return order.split('|');
                    } else {
                        return [];
                    }
                },
                set: function (sortable) {
                    let order = sortable.toArray();
                    localStorage.setItem(sortable.options.group.name, order.join('|'));

                    $.ajax({
                        url: helper.data('layout'),
                        type: "post",
                        data: "k=" + sortable.options.group.name + "&v=" + order.join('|') + "&p=dashboard",
                        async: true
                    });
                }
            }
        }));
    }
}

export function unloadDashboardPage() {
    unloadChangeWebshopStockModal();

    let chart = $('#sales-chart');

    delete window.blockUI;
    delete window.Sortable;
    delete window.ApexCharts;
    delete window.magnificPopup;
    delete window.reloadCard;
    delete window.scrapProduct;

    $('a[data-action="reload"]').off('click', onReloadCard_87787b9d);

    if (!checkResponsive()) {
        $('#card-drag-area').data('sort').destroy();
    }

    if (typeof chart.data('chart') !== 'undefined') {
        chart.data('chart').destroy();
    }
}
