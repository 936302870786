import '../app/vendors/css/extensions/tether-theme-arrows.css';
import '../app/vendors/css/extensions/tether.min.css';
import '../app/vendors/css/extensions/shepherd.min.css';
import '../app/css/plugins/extensions/ext-component-tour.css';
import Shepherd from "shepherd.js";

require('tether');

$(function () {
    let tourHelper = $('#tour-helper');

    function setupTour(tour) {
        let backBtnClass = 'btn btn-sm btn-secondary', nextBtnClass = 'btn btn-sm btn-primary btn-next';

        tour.addStep({
            title: tourHelper.data('step1-title'),
            text: tourHelper.data('step1-text'),
            attachTo: {element: '.navbar', on: 'bottom'},
            buttons: [
                {
                    action: tour.cancel,
                    classes: backBtnClass,
                    text: tourHelper.data('skip')
                },
                {
                    text: tourHelper.data('next'),
                    classes: nextBtnClass,
                    action: tour.next
                }
            ]
        });
        tour.addStep({
            title: tourHelper.data('shops'),
            text: tourHelper.data('step2-text'),
            attachTo: {element: '#usergroup-menu', on: 'bottom'},
            buttons: [
                {
                    text: tourHelper.data('skip'),
                    classes: backBtnClass,
                    action: tour.cancel
                },
                {
                    text: tourHelper.data('back'),
                    classes: backBtnClass,
                    action: tour.back
                },
                {
                    text: tourHelper.data('next'),
                    classes: nextBtnClass,
                    action: tour.next
                }
            ]
        });
        tour.addStep({
            title: tourHelper.data('products'),
            text: tourHelper.data('step3-text'),
            attachTo: {element: '#products-menu', on: 'bottom'},
            buttons: [
                {
                    text: tourHelper.data('skip'),
                    classes: backBtnClass,
                    action: tour.cancel
                },
                {
                    text: tourHelper.data('back'),
                    classes: backBtnClass,
                    action: tour.back
                },
                {
                    text: tourHelper.data('next'),
                    classes: nextBtnClass,
                    action: tour.next
                }
            ]
        });
        tour.addStep({
            title: tourHelper.data('orders'),
            text: tourHelper.data('step4-text'),
            attachTo: {element: '#invoices-menu', on: 'bottom'},
            buttons: [
                {
                    text: tourHelper.data('skip'),
                    classes: backBtnClass,
                    action: tour.cancel
                },
                {
                    text: tourHelper.data('back'),
                    classes: backBtnClass,
                    action: tour.back
                },
                {
                    text: tourHelper.data('next'),
                    classes: nextBtnClass,
                    action: tour.next
                }
            ]
        });
        tour.addStep({
            title: tourHelper.data('helpdesk'),
            text: tourHelper.data('step5-text'),
            attachTo: {element: '#helpdesk-menu', on: 'bottom'},
            buttons: [
                {
                    text: tourHelper.data('back'),
                    classes: backBtnClass,
                    action: tour.back
                },
                {
                    text: tourHelper.data('finish'),
                    classes: nextBtnClass,
                    action: tour.cancel
                }
            ]
        });

        return tour;
    }

    let tourVar = new Shepherd.Tour({
        defaultStepOptions: {
            classes: 'shadow-md bg-purple-dark',
            scrollTo: false,
            cancelIcon: {
                enabled: false
            }
        },
        useModalOverlay: true
    });

    tourVar.on('complete', function () {
        let url = tourHelper.data('url');

        $.ajax({
            url: url,
            type: "post",
            data: 'f=t',
            async: true
        });
    });

    tourVar.on('cancel', function () {
        let url = tourHelper.data('url');

        $.ajax({
            url: url,
            type: "post",
            data: 'f=t',
            async: true
        });
    });

    setupTour(tourVar).start();
});
